<template>
	<div class="pdf-wrap">
		<section class="patient-pdf-data-wrapper" id="pdf">
			<div class="head">
				<img src="@/assets/img/labload.jpg" />
				<h1>Auftrag von {{ record.doctor.name }}</h1>
				<h4>Erstellungsdatum {{ record.date_created | parseDateTime }}</h4>
				<h4>Akten ID: {{ record.id }}</h4>
			</div>
			<hr />
			<div class="patient">
				<div class="left">
					<p>
						<span class="headline">Vorname:</span>
						{{ record.overview.firstname }}
					</p>
					<p>
						<span class="headline">Nachname:</span>
						{{ record.overview.lastname }}
					</p>
					<p>
						<span class="headline">Alter:</span>
						{{ record.extended.patient.alter }}
					</p>
					<p>
						<span class="headline">XML-Nummer:</span>
						{{ record.extended.order.xmlNumber }}
					</p>
				</div>
				<div class="right">
					<p>
						<span class="headline">Geschlecht:</span>
						{{ record.extended.patient.geschlecht }}
					</p>
					<p>
						<span class="headline">Versorgung:</span>
						{{ record.extended.patient.versorgung }}
					</p>
					<p>
						<span class="headline">Versicherung:</span>
						{{ record.extended.patient.versicherung }}
					</p>
				</div>
			</div>
			<hr />
			<div class="order_data">
				<div class="left">
					<p>
						<span class="headline">Rücksprache erwünscht:</span>
						{{ record.extended.order.callBack === '1' ? 'Ja' : 'Nein' }}
					</p>
					<p>
						<span class="headline">Kostenvoranschlag:</span>
						{{ record.extended.order.offer === '1' ? 'Ja' : 'Nein' }}
					</p>
					<p>
						<span class="headline">Lieferung:</span>
						{{ record.extended.order.delivery }}
					</p>
				</div>
				<div class="right">
					<p>
						<span class="headline"
							>{{ record.extended.order.firstDateType }}:</span
						>
						{{ record.extended.order.firstDate | parseDate }}
					</p>
					<p v-if="record.extended.order.secondDate !== ''">
						<span class="headline"
							>{{ record.extended.order.secondDateType }}:</span
						>
						{{ record.extended.order.secondDate | parseDate }}
					</p>
					<p v-if="record.extended.order.thirdDate !== ''">
						<span class="headline"
							>{{ record.extended.order.thirdDateType }}:</span
						>
						{{ record.extended.order.thirdDate | parseDate }}
					</p>
				</div>
			</div>
			<hr />
			<div class="order">
				<p>
					<span class="headline">Zahnfarbe:</span>
					{{ record.extended.order.color }}
				</p>
				<p>
					<span class="headline">Material:</span>
					{{ record.extended.order.material }}
				</p>
				<p><span class="headline">Auftrag:</span></p>
				<p class="order_text">
					{{ record.extended.order.order }}
				</p>
			</div>
			<hr />
			<div class="schema">
				<div
					class="schema-values top-row"
					:class="{
						last: firstRow.length - 1 === parseInt(index)
					}"
					v-for="(s, index) in firstRow"
					:key="s.order"
				>
					<p>{{ s.value || '-' }}</p>
					<p>{{ s.name }}</p>
				</div>
				<div
					class="schema-values bottom-row"
					:class="{
						last: parseInt(index) === lastRow.length - 1
					}"
					v-for="(s, index) in lastRow"
					:key="s.order"
				>
					<p>{{ s.name }}</p>
					<p>{{ s.value || '-' }}</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import moment from 'moment';
export default {
	props: ['record'],
	filters: {
		parseDateTime(value) {
			return moment(value).format('DD.MM.YYYY HH:mm');
		},
		parseDate(value) {
			return moment(value).format('DD.MM.YYYY');
		}
	},
	computed: {
		firstRow: function () {
			let d = [...this.record.extended.schema];
			return d.sort(this.compare).splice(0, 16);
		},
		lastRow: function () {
			let d = [...this.record.extended.schema];
			return d.sort(this.compare).splice(16, 32);
		}
	},
	methods: {
		getLogo() {
			return (
				this.$store.getters.getLab.logo ||
				'https://storage.googleapis.com/uploadportal-logos/labload.png'
			);
		}
	}
};
</script>

<style lang="scss">
.patient-pdf-data-wrapper {
	color: $text-color;
	visibility: hidden;
	.head {
		h1 {
			font-size: 10px;
		}
		h4 {
			font-size: 5px;
			margin-top: 2px;
		}
		img {
			width: 90px;
			margin: 0 auto;
			image-rendering: crisp-edges;
		}
	}
	.headline {
		font-weight: bold;
	}
	hr {
		width: 175px;
		margin: 4px 0;
		border-width: 1px;
		color: $dark-grey;
	}
	.patient,
	.order_data {
		display: grid;
		grid-template-columns: 87px 87px;
		.left,
		.right {
			p {
				font-size: 5px;
			}
		}
	}
	.order {
		width: 175px;
		p {
			font-size: 5px;
		}
		.order_text {
			width: 175px;
			font-size: 5px;
		}
	}
	.schema {
		width: 175px;
		margin-top: 4px;
		.schema-values {
			width: calc(175px / 16);
			float: left;
			font-size: 6px;
			p:first-child {
				background-color: transparent;
				border: none;
				font-size: 6px;
				padding: 0 1px;
				text-align: center;
			}
			p:last-child {
				text-align: center;
				background-color: transparent;
			}
			&.top-row {
				border-width: 0 1px 1px 0;
				border-style: solid;
				border-color: $text-color;
				p:last-child {
					border-top: 1px solid $text-color;
					font-weight: bold;
				}
				&.last {
					border-width: 0 0 1px 0;
				}
			}
			&.bottom-row {
				border-width: 0 1px 0 0;
				border-style: solid;
				border-color: $text-color;
				p:first-child {
					border-bottom: 1px solid $text-color;
					font-weight: bold;
				}
				&.last {
					border-width: 0 0 0 0;
				}
			}
		}
	}
}
</style>
